class HeroHomeVideo extends HTMLElement {
  constructor() {
    super();
    this.$video = this.querySelector('video');
    this.$pauseToggle = document.querySelector('[data-pause-toggle]');
    this.$pauseIcon = document.querySelector('[data-pause-icon]');
    this.$playIcon = document.querySelector('[data-play-icon]');
    this.localStorageKey = 'pauseState';
    this.videoLoaded = false;
  }

  static get observedAttributes() {
    return ['playing'];
  }

  get playing() {
    return this.hasAttribute('playing');
  }

  set playing(value) {
    if (value) {
      this.setAttribute('playing', '');
    } else {
      this.removeAttribute('playing');
    }
  }

  connectedCallback() {
    this.initPauseToggle();

    // if user doesn't prefer reduced motion AND the state is not set to paused...
    const reduce = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (!reduce && !this.getPauseState()) {
      this.playing = true;
    }

    this.addEventListener('pause', () => {
      this.playing = false;
    });

    this.addEventListener('play', () => {
      this.playing = true;
    });
  }

  attributeChangedCallback(name) {
    if (name === 'playing') {
      this.playPause();
    }
  }

  /*
  * this method moves the data-src value to the src attribute of each source element
  * within the video element (with some hacking).
  * */
  connectDataSources() {
    if (this.videoLoaded) return;

    // if the user does not prefer reduced motion AND
    // the user did not previously pause the video
    this.$video.querySelectorAll('source').forEach(($source) => {
      // Move the value of  the data-src attribute to the src attribute.
      $source.setAttribute('src', $source.dataset.src);

      // Remove the source element from the video element because
      // it was already rejected by the browser.
      $source.remove();

      // Then reintroduce the source element to the video element
      // so the browser can re-evaluate it
      this.$video.appendChild($source.cloneNode(true));

      // Tell the component that the video was loaded
      this.videoLoaded = true;
    });
  }

  initPauseToggle() {
    // find the pauseToggle button and give it a click event
    this.$pauseToggle.addEventListener('click', () => {
      this.playing = !this.playing;
    });
  }

  toggleIcon(play) {
    if (play) {
      this.$playIcon.hidden = false;
      this.$pauseIcon.hidden = true;
    } else {
      this.$playIcon.hidden = true;
      this.$pauseIcon.hidden = false;
    }
  }

  playPause() {
    this.connectDataSources();

    if (this.playing) {
      // remove the pause state
      localStorage.removeItem(this.localStorageKey);

      // ...play or resume the video element...
      this.$video.play();

      // ...and switch the icon to a pause icon
      this.toggleIcon(false);
    } else {
      // store the pause state
      localStorage.setItem(this.localStorageKey, 'true');

      // pause the video element...
      this.$video.pause();

      // ...and switch the icon to a play icon
      this.toggleIcon(true);
    }
  }

  getPauseState() {
    if (!this.localStorageKey) return false;
    return localStorage.getItem(this.localStorageKey) === 'true';
  }
}

window.customElements.define('hero-home-video', HeroHomeVideo);
