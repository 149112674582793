class FacetFilter extends HTMLElement {
  constructor() {
    super();
    this.handleClickPagination = this.handleClickPagination.bind(this);
    this.handleFormFormDataEvent = this.handleFormFormDataEvent.bind(this);
  }

  connectedCallback() {
    this.$resultsList = this.querySelector('[data-facet-filter-results]');
    this.$forms = [...this.querySelectorAll('[data-facet-filter-form]')];
    if (this.$forms.length) {
      this.setupAutoSubmit();
    }
    this.$paginationLinks = [...this.querySelectorAll('[data-facet-filter-pagination-link]')];
    if (this.$paginationLinks.length) {
      this.enhancePagination();
    }
  }

  disconnectedCallback() {
    this.$paginationLinks.forEach(($link) => {
      $link.removeEventListener('click', this.handleClickPagination);
    });
    // check for checkbox (un)checks
    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.removeEventListener('change', this.handleCheckboxChange);
    });
    this.$forms.forEach(($form) => {
      $form.removeEventListener('submit', this.handleFormSubmit);
      $form.removeEventListener('formdata', this.handleFormFormDataEvent);
    });
  }

  handleClickPagination(e) {
    // it's a link, but don't follow it
    e.preventDefault();
    // update url 'manually'
    window.history.replaceState(null, '', `${e.currentTarget.href}`);
    // fetch results
    this.runUpdate();
    // scroll this (the whole component) into view
    this.scrollIntoView();
  }

  handleCheckboxChange(e) {
    // .submit() fires without preventDefault(). This doesn't.
    e.target.closest('form').requestSubmit();
    return this;
  }

  enhancePagination() {
    this.$paginationLinks.forEach(($link) => {
      $link.addEventListener('click', this.handleClickPagination);
    });
  }

  setupAutoSubmit() {
    this.$autoForm = this.querySelector('[data-facet-filter-autosubmit-form]');
    this.$checkboxes = [...this.$autoForm.querySelectorAll('[data-facet-filter-checkbox]')];
    this.$submitButton = this.$autoForm.querySelector('[data-facet-filter-submit-button]');

    // check for checkbox (un)checks
    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.addEventListener('change', this.handleCheckboxChange);
    });

    // because the form submits on check (above, we can hide the button)
    this.$submitButton.classList.add('u-visually-hidden');

    this.$forms.forEach(($form) => {
      // check for form submission
      $form.addEventListener('submit', this.handleFormSubmit);
      $form.addEventListener('formdata', this.handleFormFormDataEvent);
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    // this makes the formdata event fire
    this.FormData = new FormData(e.target);
    return this;
  }

  handleFormFormDataEvent(e) {
    // Get the form data from the event object
    this.formData = e.formData;
    // build and update url
    this.updateUrl();
    // run update, according to url
    this.runUpdate();
  }

  async runUpdate() {
    this.disableControls();
    // url was already updated in the calling method. Fetch that page.
    const req = await fetch(window.location);
    const res = await req.text();
    // construct a DOM, so I can querySelect it
    const parser = new DOMParser();
    const doc = parser.parseFromString(res, 'text/html');
    // go to parent, empty it
    const $parentNode = this.parentNode;
    $parentNode.innerHTML = '';
    $parentNode.append(doc.querySelector('facet-filter'));
  }

  disableControls() {
    this.$resultsList.classList.add('is-loading');
    if (this.$checkboxes) {
      this.$checkboxes.forEach(($checkbox) => {
        $checkbox.disabled = true;
      });
    }
  }

  updateUrl() {
    // build a url: ?filter=filter_1&filter_filter_2
    const params = new URLSearchParams();
    [...this.formData.entries()].forEach(([key, value]) => {
      params.append(key, value);
    });
    // update the url
    window.history.replaceState(null, '', `?${params.toString()}`);
  }
}

customElements.define('facet-filter', FacetFilter);
