class StickyBar extends HTMLElement {
  connectedCallback() {
    this.open = false;
    this.$trigger = document.querySelector('[data-stickybar-trigger]');
    this.$bar = this.querySelector('[data-stickybar]');
    this.$btnToggle = this.querySelector('[data-sticky-nav-button]');
    this.$btnLabel = this.$btnToggle.querySelector('[data-module-bind="btn-label"]');
    // element with the id where the button with aria-controls=[id] points to
    this.$foldout = this.querySelector(`#${this.$btnToggle.getAttribute('aria-controls')}`);
    this.$jumpLinks = [...this.$foldout.querySelectorAll('a')];
    this.$jumpLinksExpanded = [...this.querySelectorAll('[data-jumplink-expanded]')];
    this.$progressBar = this.querySelector('[data-sticky-bar-progress]');
    this.$waypoints = document.querySelectorAll('[data-waypoint]');
    // update once on page load
    this.updateProgressbar();
    this.bindListeners();
    this.previousIntersectingEntries = [];
    this.observeWaypoints();
  }

  bindListeners() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].boundingClientRect.bottom < 0) {
        this.showStickyBar();
      } else {
        this.hideStickyBar();
      }
    });

    observer.observe(this.$trigger);

    this.$btnToggle.addEventListener('click', () => {
      if (!this.open) {
        this.openFoldout();
      } else {
        this.closeFoldout();
      }
    });

    if (this.$progressBar) {
      window.requestAnimationFrame(() => {
        window.addEventListener('scroll', () => {
          this.updateProgressbar();
        });
      });
    }

    this.$foldout.addEventListener('click', (e) => {
      if (e.target.closest('a')) {
        this.closeFoldout();
      }
    });
  }

  // intro and panels are observed and activate tabs in the stickybar
  observeWaypoints() {
    const observer = new IntersectionObserver((entries) => {
      // Create array of the current entries and the entries of the previous callback.
      const entriesToCompare = entries.concat(this.previousIntersectingEntries);
      // If all the entries have the same waypointId,
      // we don't want to compare them, end the callback.
      if (entriesToCompare.every((entry) => (
        entry.target.id === entriesToCompare[0].target.id
      ))) {
        return;
      }

      // Compare all entries for the highest intersectionRatio
      const highestIntersectingEntry = entriesToCompare.reduce((prevEntry, entry) => {
        if (!prevEntry) {
          return entry;
        }
        if (entry.intersectionRatio > prevEntry.intersectionRatio) {
          return entry;
        }
        return prevEntry;
      });

      // Update toggler with the highest intersectionRatio
      this.updateSectionIndicator(highestIntersectingEntry.target.id);
      // Store current entries for future comparison
      this.previousIntersectingEntries = entries;
    }, {
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    });

    [...this.$waypoints].forEach(($waypoint) => {
      observer.observe($waypoint);
    });
  }

  updateSectionIndicator(id) {
    this.$jumpLinksExpanded.forEach(($link) => {
      // console.log($link, id)
      if ($link.hash === `#${id}`) {
        $link.setAttribute('aria-current', 'true');
      } else {
        $link.removeAttribute('aria-current');
      }
    });

    this.$jumpLinks.forEach(($link) => {
      $link.removeAttribute('aria-current');
    });

    const $activeLink = this.$foldout.querySelector(`a[href="#${id}"]`);
    $activeLink.setAttribute('aria-current', 'true');

    this.$btnLabel.innerHTML = $activeLink.dataset.label;
  }

  showStickyBar() {
    this.$bar.classList.add('is-visible');
  }

  hideStickyBar() {
    this.$bar.classList.remove('is-visible');
  }

  openFoldout() {
    this.$btnToggle.setAttribute('aria-expanded', 'true');
    this.$foldout.hidden = false;
    this.open = true;
  }

  closeFoldout() {
    this.$btnToggle.setAttribute('aria-expanded', 'false');
    this.$foldout.hidden = true;
    this.open = false;
  }

  updateProgressbar() {
    const dims = document.body.getBoundingClientRect();
    const progress = dims.top / (dims.height - window.innerHeight);
    this.$progressBar.value = 0 - progress;
  }
}

customElements.define('sticky-bar', StickyBar);
