class AccordeonOpener extends HTMLElement {

  get controls() {
    return this.getAttribute('controls');
  }

  connectedCallback() {
    this.$accordeonItem = document.querySelector(`#${this.controls}`);
    this.$link = this.querySelector('a');
    this.$link.addEventListener('click', () => {
      this.$accordeonItem.open = true;
    });
  }
}

customElements.define('accordeon-opener', AccordeonOpener);
