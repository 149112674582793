import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class CardSlider extends HTMLElement {
  connectedCallback() {
    this.$slider = this.querySelector('[data-module-bind=slider]');
    this.$progress = this.querySelector('[data-slider-progress]');
    this.$controls = this.querySelector('[data-card-slider-controls]');
    const $btnPrev = this.querySelector('[data-button-prev]');
    const $btnNext = this.querySelector('[data-button-next]');

    this.swiper = new Swiper(this.$slider, {
      modules: [Navigation],
      slidesPerView: 'auto',
      navigation: {
        nextEl: $btnNext,
        prevEl: $btnPrev,
      },
    });

    if (!this.swiper.isLocked) {
      this.$controls.hidden = false;
    }

    if (this.$progress) {
      this.swiper.on('progress', (swiper, progress) => {
        this.$progress.setAttribute('value', progress.toString());
      });

      this.swiper.on('lock', () => {
        this.$controls.hidden = true;
      });

      this.swiper.on('unlock', () => {
        this.$controls.hidden = false;
      });
    }
  }
}

customElements.define('card-slider', CardSlider);
