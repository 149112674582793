class DetailsModal extends HTMLElement {
  static get observedAttributes() {
    return ['open'];
  }

  get controls() {
    return this.getAttribute('controls');
  }

  get type() {
    return this.getAttribute('type');
  }

  get open() {
    return this.hasAttribute('open');
  }

  set open(value) {
    if (value) {
      this.setAttribute('open', '');
    } else {
      this.removeAttribute('open');
    }
  }

  connectedCallback() {
    this.$modal = this.querySelector(`#${this.controls}`);

    if (!this.$modal) return;

    // check if <dialog> is not supported
    if (typeof document.createElement('dialog').show !== 'function') {
      (async () => {
        // import js dynamically, run the function
        const dialogPolyfill = await import('dialog-polyfill');
        dialogPolyfill.default.registerDialog(this.$modal);
        // import css too
        import('dialog-polyfill/dialog-polyfill.css');
      })();
    }

    this.$togglers = this.querySelectorAll(`[aria-controls="${this.controls}"]`);

    this.$togglers.forEach(($toggler) => {
      $toggler.addEventListener('click', () => {
        this.open = !this.open;
      });
    });

    this.$modal.addEventListener('close', () => {
      this.open = false;
    });
  }

  attributeChangedCallback(name) {
    if (name === 'open') {
      if (this.open) {
        this.openModal();
        return;
      }

      this.closeModal();
    }
  }

  openModal() {
    this.$modal.showModal();

    if (this.type === 'video') {
      // autoplay the video embed
      this.querySelector('video-embed').dispatchEvent(new CustomEvent('start'));
      // pause the background video if it is playing
      const bgVideo = this.querySelector('hero-home-video');
      this.bgVideoIsPlaying = bgVideo.hasAttribute('playing');
      this.querySelector('hero-home-video').dispatchEvent(new CustomEvent('pause'));
    }
  }

  closeModal() {
    this.$modal.close();

    if (this.type === 'video') {
      // stop the video-embed
      this.querySelector('video-embed').dispatchEvent(new CustomEvent('stop'));
      // resume video if it was playing
      if (this.bgVideoIsPlaying) {
        this.querySelector('hero-home-video').dispatchEvent(new CustomEvent('play'));
      }
    }
  }
}

customElements.define('details-modal', DetailsModal);
