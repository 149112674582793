export default class CustomToggle extends HTMLElement {
  static get observedAttributes() {
    return ['open'];
  }

  set controls(value) {
    if (value) {
      this.setAttribute('controls', '');
    } else {
      this.removeAttribute('controls');
    }
  }

  get controls() {
    return this.getAttribute('controls');
  }

  get also() {
    return this.getAttribute('also');
  }

  get open() {
    return this.hasAttribute('open');
  }

  set open(value) {
    if (value) {
      this.setAttribute('open', '');
    } else {
      this.removeAttribute('open');
    }
  }

  connectedCallback() {
    this.$panel = this.querySelector(`#${this.controls}`);

    if (this.also) {
      this.$also = this.querySelector(`#${this.also}`);
    }

    if (!this.$panel) return;

    this.$togglers = this.querySelectorAll(`[aria-controls="${this.controls}"]`);

    this.$togglers.forEach(($toggler) => {
      $toggler.setAttribute('aria-expanded', this.open.toString());

      $toggler.addEventListener('click', () => {
        this.open = !this.open;
      });
    });
  }

  attributeChangedCallback(name) {
    if (name === 'open') {
      this.$togglers.forEach(($toggler) => {
        $toggler.setAttribute('aria-expanded', this.open.toString());
      });

      this.$panel.hidden = !this.open;

      if (this.$also) {
        this.$also.hidden = !this.open;
      }
    }
  }
}

customElements.define('custom-toggle', CustomToggle);
