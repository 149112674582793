class ShareButton extends HTMLElement {
  connectedCallback() {
    this.$shareButton = this.querySelector('[data-share-button]');

    if (!navigator.canShare) {
      this.canShare = false;
      this.$shareButton.hidden = true;
      this.$fallback = this.querySelector('[data-share-button-fallback]');
      this.$fallback.hidden = false;
      return;
    }

    this.$shareButton.addEventListener('click', () => {
      this.handleClickShare();
    });
  }

  async handleClickShare() {
    const shareData = {
      title: document.title,
      text: document.querySelector('meta[name=description]').getAttribute('content'),
      url: window.location.href,
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      // console.error(err);
    }

    return this;
  }
}

window.customElements.define('share-button', ShareButton);
