class VideoEmbed extends HTMLElement {
  constructor() {
    super();
    this.start = this.start.bind(this);
  }

  connectedCallback() {
    this.$btn = this.querySelector('[data-video-embed-btn-play]');
    this.$message = this.querySelector('[data-video-embed-message]');
    this.$video = this.querySelector('[data-video-embed-video]');
    this.$renew = this.querySelector('[data-renew-consent]');
    this.$btn.addEventListener('click', this.start);

    this.addEventListener('start', () => {
      this.start();
    });

    this.addEventListener('stop', () => {
      this.stop();
    });

    this.$renew.addEventListener('click', () => {
      const $modal = this.closest('details-modal');

      // check if we are in a modal, close it
      if ($modal) {
        $modal.dispatchEvent(new CustomEvent('close'));
      }

      window.Cookiebot.renew();
    });
  }

  start() {
    this.$message.hidden = false;

    if (window.CookieConsent?.consent.marketing) {
      this.$video.hidden = false;
      this.$video.src = this.$video.dataset.lazySrc;
    } else {
      // console.warn('Cookie Consent not given, cannot display video.');
    }
  }

  stop() {
    this.$video.removeAttribute('src');
  }
}

customElements.define('video-embed', VideoEmbed);
