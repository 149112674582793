class HeaderMenu extends HTMLElement {
  connectedCallback() {
    this.isOpen = false;
    this.$header = this.querySelector('[data-header]');
    this.$toggleBtn = this.querySelector('[data-header-toggle-menu]');
    this.$primaryNav = this.querySelector('[data-header-primary-nav]');
    this.$foldout = this.querySelector('[data-header-foldout]');

    this.$toggleBtn.addEventListener('click', () => {
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
    });
  }

  open() {
    this.isOpen = true;
    this.$toggleBtn.setAttribute('aria-expanded', 'true');
    this.$primaryNav.hidden = true;
    this.$header.classList.add('is-open');
    this.$foldout.hidden = false;
  }

  close() {
    this.isOpen = false;
    this.$toggleBtn.setAttribute('aria-expanded', 'false');
    this.$primaryNav.hidden = false;
    this.$header.classList.remove('is-open');
    this.$foldout.hidden = true;
  }
}

customElements.define('header-menu', HeaderMenu);
