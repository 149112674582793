import { css, html, LitElement } from 'lit';

class LineClamp extends LitElement {
  static properties = {
    open: {
      type: Boolean,
      reflect: true,
    },
  };

  static styles = css`
    :host {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: var(--lines);
      overflow: hidden;
    }

    :host([open]) {
      --lines: none !important;
    }
  `;

  constructor() {
    super();
    this.toggleClamp = this.toggleClamp.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return html`
      <slot></slot>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
    this.$btn = document.querySelector(`[aria-controls=${this.id}]`);
    this.$btn.addEventListener('click', this.toggleClamp);
    this.checkHeight();
  }

  toggleClamp() {
    this.open = !this.open;
  }

  checkHeight() {
    this.resizeObserver = new ResizeObserver(($entries) => {
      $entries.forEach(($entry) => {
        if ($entry.target.scrollHeight > $entry.target.clientHeight) {
          this.showDivider();
        } else {
          this.hideDivider();
        }
      });
    });

    this.resizeObserver.observe(this);
  }

  showDivider() {
    this.$btn.hidden = false;
  }

  hideDivider() {
    this.$btn.hidden = true;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.$btn.removeEventListener('click', this.toggleClamp);
    this.resizeObserver.unobserve(this);
  }
}

customElements.define('line-clamp', LineClamp);
