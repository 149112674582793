class TabNavigation extends HTMLElement {
  constructor() {
    super();
    this.handleClickTab = this.handleClickTab.bind(this);
  }

  connectedCallback() {
    this.$tablist = this.querySelector('[role=tablist]');
    this.$tabs = [...this.$tablist.querySelectorAll('[role=tab]')];
    this.$panels = [...this.querySelectorAll('[role=tabpanel]')];
    this.$tabs.forEach(($tab) => {
      $tab.addEventListener('click', this.handleClickTab);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.$tabs.forEach(($tab) => {
      $tab.removeEventListener('click', this.handleClickTab);
    });
  }

  handleClickTab(e) {
    // set all tabs inactive
    this.$tabs.forEach(($tab) => {
      $tab.setAttribute('aria-selected', 'false');
      $tab.setAttribute('tabindex', '-1');
    });
    // set 1 active tab
    e.target.setAttribute('aria-selected', 'true');
    e.target.setAttribute('tabindex', '0');
    // set all panels hidden
    this.$panels.forEach(($panel) => {
      $panel.hidden = true;
    });
    // show 1 panel
    const activetab = e.target.getAttribute('aria-controls');
    const $panelToActivate = this.$panels.find(($panel) => $panel.id === activetab);
    $panelToActivate.hidden = false;
  }
}

customElements.define('tab-navigation', TabNavigation);
